@font-face {
  font-family: 'Script';
  src: url('../assets/fonts/advertisingScript/advertisingScript.ttf');
}
@font-face {
  font-family: 'Jost';
  src: url('../assets/fonts/jost/Jost-300-Light.ttf');
}
@font-face {
  font-family: 'lora';
  src: url('../fonts/lora/Lora-Regular.otf');
}
.lora {
  font-family: 'lora';
}
.righteous {
  font-family: 'Righteous', sans-serif;
  font-weight: 400;
  font-style: normal;
}
.script {
  font-family: 'Righteour';
}
.logo {
  color: var(--bs-dark);
  font-family: 'Righteous';
  text-decoration: none;
  font-size: 1.75rem;
  padding-bottom: 0;
}
.navbar-nav .nav-link {
  margin-right: 16px;
}
.app-footer a {
  text-decoration: none;
}
.pointer {
  cursor: pointer;
}
.app-footer {
  background-color: var(--bs-dark);
  color: var(--bs-light);
  padding: 20px 0 0 0;
}
.quick-links li {
  list-style-type: none;
  font-size: 16px;
  font-family: 'Jost';
  opacity: 0.6;
  cursor: pointer;
}
.quick-links li:hover {
  opacity: 1;
}
.bold {
  font-weight: bold;
}
.looking-for {
  display: flex;
  align-items: center;
}
.carousel-card {
  border: 1px solid var(--bs-gray-300);
  box-shadow: 0 0 10px var(--bs-gray-300);
  border-radius: 10px;
  overflow: hidden;
}
.carousel-card .carousel-body {
  padding: 14px;
}
.carousel-card .carousel-img {
  height: 420px;
  content: '';
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
/* @media screen and (max-width: 475px) {
  .carousel-card .carousel-img {
    height: 210px;
  }
} */
.test-card {
  border: 1px solid var(--bs-gray-300);
  box-shadow: 0 0 10px var(--bs-gray-300);
  border-radius: 10px;
  overflow: hidden;
}
.test-card .test-card-body {
  padding: 14px;
}
.test-card .test-card-img {
  height: 240px;
  content: '';
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading-alt {
  text-decoration: underline;
  font-family: 'Righteous';
}
.sub-heading {
  font-style: italic;
}
.heading {
  font-family: 'Righteous';
  text-decoration: underline;
}
.checkmark-list {
  list-style-type: none; /* Remove default list styles */
  padding-left: 0; /* Remove default padding */
}

.checkmark-list li:before {
  content: '\2713'; /* Unicode check mark character */
  color: green; /* Color of the check mark */
  display: inline-block;
  width: 1em; /* Adjust as needed */
  margin-left: -1em; /* Ensure check mark is aligned properly */
}
.my-50 {
  margin: 5rem 0;
}
.franchise-card {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px var(--bs-gray-300);
}
.franchise-address p {
  font-size: 14px;
  margin-bottom: 8px;
}
.box {
  border-radius: 10px;
  box-shadow: 0 0 10px var(--bs-gray-400);
}
.text-sm {
  font-size: 12px;
}
.error {
  font-size: 12px;
  color: var(--bs-danger);
}
/* .profile-modal{

} */
.text-dark2 {
  color: #100d55;
}
.details-info {
  padding: 2px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--bs-gray-200);
  display: flex;
  p {
    margin-bottom: 0;
  }
  .label {
    font-family: 'Arial';
    min-width: 30%;
    font-size: 18px;
    margin-right: 5px;
    font-weight: bold;
  }
  .value {
    font-family: 'Arial';
    font-size: 18px;
  }
}
.bold {
  font-weight: bold;
}
.arial {
  font-family: 'Arial';
}
.modal-profile-image {
  height: 360px;
  overflow: hidden;
}
.italic {
  font-style: italic;
}
@font-face {
  font-family: 'jost';
  src: url('../fonts/jost/Jost-300-Light.ttf');
}
p {
  font-family: 'jost';
}
.jost {
  font-family: jost;
}
.profile-heading {
  font-family: 'lora';
  font-weight: bold;
  font-style: italic;
  color: #a84823;
  text-decoration: underline;
}
/*Footer*/
a {
  color: white;
}

.footer {
  background-color: var(--bs-dark);
  color: var(--bs-light);
}

.sb-footer {
  display: flex;
  flex-direction: column;
}

.sb-footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  text-align: left;
  margin-bottom: -2.5rem;
}

.sb-footer-links-div {
  width: 300px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
}

.footer-link {
  color: rgb(175, 175, 179);
  text-decoration: none;
}

.sb-footer-links-div h4 {
  font-size: 30px;
  line-height: 17px;
  margin-bottom: 1.9rem;
}

.sb-footer-links-div p {
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 0.5rem;
}

.sb-footer-below {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
}

.sb-footer-below-links {
  display: flex;
  flex-direction: row;
}

.sb-footer-below-links p {
  font-size: 15px;
  line-height: 15px;
  margin-left: 2rem;
  color: white;
  font-weight: 600;
}

hr {
  color: white !important;
  width: 100%;
}

.sb-footer-copright p {
  font-size: 15px;
  line-height: 15px;
  color: rgb(255, 255, 255);
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  /*Footer*/
  .sb-footer-below {
    display: block;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
  }

  .sb-footer-below-links p {
    font-size: 15px;
    line-height: 15px;
    margin-left: 0rem;
    color: white;
    font-weight: 600;
  }
}

.profile-img-container {
  .img-main {
    width: 100%;
  }
  .img-thumb {
    width: 128px;
    border: 2px solid transparent;
  }
  .img-thumb.active {
    border: 2px solid var(--bs-primary);
  }
}
@media screen and (max-width: 475px) {
  .modal-body {
    padding: 0;
  }
}
.h90 {
  min-height: 90vh;
}
.page-input {
  display: inline-flex;
  width: 120px;
}
.page-input input::-webkit-inner-spin-button,
.page-input input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-arrow {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
.react-select__input-container {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.react-select__menu {
  background-color: white;
  z-index: 99999 !important;
}
.gallery-item {
  display: block;
  img {
    width: 100%;
  }
}
